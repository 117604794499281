import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Select from 'reusecore/src/elements/Select';
import Container from 'common/src/components/UI/Container';
import ContactSections from '../Contact';
import FooterWrapper, { List, ListItem, BgImageWrapper } from './footer.style';
import AppImage from 'common/src/assets/image/crypto/footerapp.svg';
import PlaystoreImage from 'common/src/assets/image/crypto/footerplay.svg';
import FooterBG from 'common/src/assets/image/crypto/footer-bg.svg';
import GambleAwareImage from 'common/src/assets/image/crypto/gambleaware.png';
import UnderageImage from 'common/src/assets/image/crypto/over18warning.png';

const Footer = ({ row, col, colOne, colTwo, titleStyle, previewData }) => {
  const Data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Footer EN" } }) {
        frontmatter {
          contact {
            contactSection
            visible
          }
          languageSelect {
            label
            value
            path
          }
          menus {
            items {
              path
              title
            }
            title
          }
          appSection {
            title
            ios {
              path
              visible
            }
            android {
              path
              visible
            }
            visible
          }
          copyright
        }
      }
    }
  `)['markdownRemark']['frontmatter'];

  return (
    <FooterWrapper id="footerSection">
      {!previewData && Data.contact.visible && <ContactSections />}
      <BgImageWrapper>
        <Image src={FooterBG} alt="Footer background" />
      </BgImageWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box className="row mainRow" {...row}>
          <Box {...colOne}>
            <Text content="Language" {...titleStyle} />
            <Select
              options={Data.languageSelect}
              onChange={selection => {
                return (window.location.href = selection.path);
              }}
              placeholder="English"
              className="Language_search_select"
              aria-label="Language_search_input"
            />
            {Data.appSection.visible && (
              <>
                <Text
                  className="appDownload"
                  content={Data.appSection.title}
                  {...titleStyle}
                />
                <Box className="imageWrapper">
                  {Data.appSection.ios.visible && (
                    <a href={Data.appSection.ios.path}>
                      <Image src={AppImage} alt="App Image" />
                    </a>
                  )}
                  {Data.appSection.android.visible && (
                    <a href={Data.appSection.android.path}>
                      <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
                    </a>
                  )}
                </Box>
              </>
            )}
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.menus &&
              Data.menus.map(widget => (
                <Box className="col" {...col} key={widget.title}>
                  <Text content={widget.title} {...titleStyle} />
                  <List>
                    {widget.items &&
                      widget.items.map(item => (
                        <ListItem key={`list__item-${item.title}`}>
                          <a className="ListItem" href={item.path}>
                            {item.title}
                          </a>
                        </ListItem>
                      ))}
                  </List>
                </Box>
              ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row}>
          <Text
            className="copyRightText"
            content={
              <i>
                In theory matched betting is a risk-free way to make money
                online. However it is not 100% risk free in practice because of
                human errors and bookmaker restrictions. To minimize the risk
                always make sure to check the odds before placing bets and
                carefully read through the T&Cs of the bookmaker bonus offers
                before signing up.
              </i>
            }
          />
          <Text
            className="copyRightText"
            content={
              <>
                Oddshero is committed to supporting
                <a
                  href="http://www.gambleaware.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  <u>Responsible Gambling</u>
                </a>
                . Underage gambling is an offense.
                <br />
                <img src={GambleAwareImage} alt="Gamble Aware" width="150" />
                <img
                  src={UnderageImage}
                  alt="Underage Restriction"
                  width="50"
                />
              </>
            }
          />
        </Box>
        <Box className="row copyRight" {...row}>
          <Text content={Data.copyright} className="copyRightText" />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
