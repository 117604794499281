import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';

import ContactFromWrapper, {
  SectionMainWrapper,
  SuccessMessage,
  ErrorMessage,
} from './contact.style';

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  description,
  previewData,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Contact Section EN" } }) {
        frontmatter {
          title
          topText {
            title
            text
          }
          buttonText
          emailPlaceholder
          bottomText {
            text
          }
        }
      }
    }
  `)['markdownRemark']['frontmatter'];

  title['content'] = Data.topText.title;
  description['content'] = Data.topText.text;

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const handleChange = e => {
    console.log(e);
    setEmail(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(() => {
        setSuccess('Thanks. You are signed up!');
        setError('');
        setEmail('');
      })
      .catch(err => {
        setSuccess('Thanks. You are signed up!');
        setError('');
        setEmail('');
      });
  };

  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container className="containerClass">
          <Box {...secTitleWrapper}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>
          <Box {...row}>
            <Box {...contactForm}>
              <ContactFromWrapper>
                {success ? (
                  <SuccessMessage>{success} 🙂</SuccessMessage>
                ) : (
                  <>
                    <Input
                      inputType="email"
                      placeholder={Data.emailPlaceholder}
                      iconPosition="right"
                      isMaterial={false}
                      className="email_input"
                      aria-label="email"
                      onChange={e => handleChange(e)}
                      value={email}
                    />
                    <Button
                      {...button}
                      title={Data.buttonText}
                      onClick={handleSubmit}
                    />
                  </>
                )}
              </ContactFromWrapper>
              {error && (
                <ErrorMessage
                  dangerouslySetInnerHTML={{ __html: `<span>*</span>${error}` }}
                />
              )}
              <Box className="contactdes">
                <Text as="span" {...note} content={Data.bottomText.text} />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Get The Latest PayBear Updates',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },

  description: {
    content: 'And be the first to know when our crowdsale launches!.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
};

export default ContactSection;
